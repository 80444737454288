import React from 'react'
import './approver.css'

function ApproverLogin() {
    return (
        <div className='main'>
            <div className='mx-3'>
                <form>
                    <div className="col-md-3 my-3">
                        <label className="form-label">User Name</label>
                        <input type="text" className="form-control"/>

                    </div>
                    <div className="col-md-3 my-3">
                        <label className="form-label">Password</label>
                        <input type="password" className="form-control"/>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default ApproverLogin