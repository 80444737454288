import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import apiClient from "../../api";
import heading from "../Photos/heading.png";

function UploadPhoto() {
  const [uploadData, setUploadData] = useState({
    profilephoto: "",
    fullphoto: "",
  });
  const [getUploadCount, setGetUploadCount] = useState({
    profilephoto: 0,
    fullphoto: 0,
  });
  const [image1Src, setImage1Src] = useState("");
  const [image2Src, setImage2Src] = useState("");
  const profileRef = useRef();
  const fullRef = useRef();
  const formhandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    uploadData.profilephoto &&
      formData.append(
        "profilephoto",
        uploadData.profilephoto,
        uploadData.profilephoto.name
      );
    uploadData.fullphoto &&
      formData.append(
        "fullphoto",
        uploadData.fullphoto,
        uploadData.fullphoto.name
      );

    try {
      await apiClient
        .post("/uploadData", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          apiClient.post("/getUploadCount").then((res) => {
            setGetUploadCount(res.data.data);
            apiClient
              .post(`/getImages`, { responseType: "json" }) // Fetch the object containing Blobs
              .then((response) => {
                const { profile, full } = response.data.data;
                console.log("profile");
                if (profile) {
                  setImage1Src(profile);
                }
                if (full) {
                  setImage2Src(full);
                }
              })
              .catch((error) => {
                console.error("Error fetching images:", error);
              });
          });
        });
    } catch (error) {
      if (error?.response.status === 400) {
        alert(error.response.data.message);
      }
    }
    removeSelectedFullPhoto();
    removeSelectedProfile();
    // console.log("data", data);
  };
  const profileHandler = (event) => {
    console.log("event", event.target.files);
    setUploadData((prev) => ({ ...prev, profilephoto: event.target.files[0] }));
  };
  const fullPhotoHandler = (event) => {
    console.log("event", event.target.files);
    setUploadData((prev) => ({ ...prev, fullphoto: event.target.files[0] }));
  };

  const removeSelectedProfile = () => {
    profileRef.current.value = "";
    setUploadData((prev) => ({ ...prev, profilephoto: "" }));
  };
  const removeSelectedFullPhoto = () => {
    fullRef.current.value = "";
    setUploadData((prev) => ({ ...prev, fullphoto: "" }));
  };

  useEffect(() => {
    apiClient.post("/getUploadCount").then((res) => {
      setGetUploadCount(res.data.data);
    });
  }, []);

  useEffect(() => {
    apiClient
      .post(`/getImages`, { responseType: "json" }) // Fetch the object containing Blobs
      .then((response) => {
        // Assuming the response data structure is { image1: Blob, image2: Blob }
        const { profile, full } = response.data.data;
        console.log(":::", profile);
        // Create Object URLs for the Blobs
        if (profile) {
          setImage1Src(profile);
        }
        if (full) {
          setImage2Src(full);
        }
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);
  useEffect(() => {
    return () => {
      if (uploadData.profilephoto) {
        URL.revokeObjectURL(uploadData.profilephoto);
      }
      if (uploadData.fullphoto) {
        URL.revokeObjectURL(uploadData.fullphoto);
      }
    };
  }, []);

  return (
    <>
      <form action="" onSubmit={formhandler} encType="multipart/form-data">
        <div className="p-3 text-primary col-md-12 text-center" >
          <img src={heading} alt="" class="col-md-4 text-center img-fluid" />
        </div>
        <h2 className="text-primary text-center col-md-12">Upload Photos</h2>

        <div className="d-grid gap-2 col-md-3 my-4 mx-3">
          <label className="text-primary fs-5">Profile Photo</label>
          <input
            className="form-control"
            type="file"
            accept="image/*"
            onChange={profileHandler}
            style={{ display: "inline-block" }}
            ref={profileRef}
          />{" "}
          {console.log("image1Src", image1Src)}
          {image1Src && (
            <img
              src={`data:${image1Src.mime};base64,${image1Src.data}`}
              alt="Profile Photo"
              width="200px"
              height="200px"
            />
          )}
          {uploadData.profilephoto && (
            <div>
              <img
                src={URL.createObjectURL(uploadData.profilephoto)}
                alt="Thumb"
                width="200px"
                height="200px"
              />

              <button
                type="button"
                class="form-control btn btn-primary"
                onClick={() => removeSelectedProfile()}
              >
                Remove this Image
              </button>
            </div>
          )}
          {/* <div class="fraction">
            <span class="numerator">
              {getUploadCount.profilephoto + " / " + 10}
            </span>
          </div> */}
          <label className="text-primary fs-5">Full Photo</label>
          <input
            className="form-control"
            type="file"
            accept="image/*"
            onChange={fullPhotoHandler}
            ref={fullRef}
          />{" "}
          {image2Src && (
            <img
              src={`data:${image2Src.mime};base64,${image2Src.data}`}
              alt="Full Photo"
              width="200px"
              height="200px"
            />
          )}
          {/* <span class="numerator">{getUploadCount.fullphoto + " / " + 10}</span> */}
          {console.log("uploadData.fullphoto", uploadData.fullphoto)}
          {uploadData.fullphoto && (
            <div>
              <img
                src={URL.createObjectURL(uploadData.fullphoto)}
                alt="Thumb"
                width="200px"
                height="200px"
              />

              <button
                type="button"
                class="form-control btn btn-primary"
                onClick={() => removeSelectedFullPhoto()}
              >
                Remove this Image
              </button>
            </div>
          )}
          <div className="text-center mt-2">
            <button type="submit" class="btn btn-primary mx-1 fs-5 col-5">
              Save
            </button>
            <Link className="btn btn-primary mx-1 fs-5 col-5" to="/match">
              Back
            </Link>
          </div>
        </div>
      </form>
    </>
  );
}

export default UploadPhoto;
