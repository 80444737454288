import { Link } from "react-router-dom";
import { useState } from "react";
// import Axios from "axios";
import apiClient from "../../api";
import heading from "../Photos/heading.png";



function EnableCandidate() {

  const [candidateid, setCandidateID] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [disableButtonVisible, setDisableButtonVisible] = useState(false);
  const [disablecandidatedata, setdisablecandidatedata] = useState([]);
  const [disablecandidatedata1, setdisablecandidatedata1] = useState(false);


  const checkId = () => {
    setdisablecandidatedata([]);
    setDisableButtonVisible(false);
    apiClient.post("https://api-gsm.mastersolutions.in/checkapplicantid1", {
      candidateid: candidateid,
    }).then((response) => {
      const { success, result } = response.data;
      if (success === "true") {
        setModalMessage("Are you sure");
        setShowModal(true);
        setDisableButtonVisible(true);
        setdisablecandidatedata(result);
        setdisablecandidatedata1(true);
      } else if (success === "false") {
        setModalMessage("ID is wrong");
        setShowModal(true);
      } else {
        setModalMessage("You cannot Enable this profile");
        setShowModal(true);
      }
    }).catch((error) => {
      console.log(error);
    });
    // apiClient.post("https://api-gsm.mastersolutions.in/checkapplicantid", {
    //     candidateid: candidateid,
    // }).then((response) => {
    //     setResponse(response.data);
    //     setShowModal(true); // Open the modal here
    // }).catch((error) => {
    //     console.log(error);
    // });
  };

  const onclickdisable = () => {
    setDisableButtonVisible(false);
    apiClient.post("/enableprofile", {
      candidateid: candidateid,
    });
    setModalMessage("Candidate Enable successfully");
    setShowModal(true);
  }

  const closeModal = () => {
    setShowModal(false);
  };

  // const approveapplicant = () => {
  //     apiClient.post("https://api-gsm.mastersolutions.in/enableprofile", {
  //         sendingCandidateID: variableCandidateID,
  //     })
  // };

  return (
    <>
      <div className="mt-2 mx-4 text-primary " >
        <img src={heading} alt="" class="col-md-3 text-center img-fluid" />
      </div>
      <div className="d-grid gap-2 col-md-3 my-3 mx-3">
        <input type="number" className="form-control" placeholder="Candidate ID" onChange={(event) => { setCandidateID(event.target.value) }} />
        <div className="text-center">
          <Link className="btn btn-primary mx-1 fs-5 col-5" to="/EnableCandidate" onClick={checkId} >Enable</Link>
          <Link className="btn btn-primary mx-1 fs-5 col-5" to="/ApproverButton">Back</Link>
        </div>
      </div>

      {showModal && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-primary">GSM says</h5>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)} ></button>
              </div>
              <div className="modal-body">
                {disablecandidatedata1 &&
                  disablecandidatedata.map((val, key) => (
                    <div key={key} className="">
                      <img src={`https://api-gsm.mastersolutions.in/uploads1/${val.profilephoto}`} className="img-thumbnail rounded mx-auto d-block" alt="..." style={{ width: "400px", height: "400px" }} />
                      <p className="text-primary fs-5 mx-2 mt-2">Candidate name: {val.candidate_firstname}</p>
                    </div>
                  ))
                }
                <i class="bi bi-exclamation-triangle-fill text-warning mx-2"></i><span class="text-primary">{modalMessage}</span>
              </div>
              <div className="modal-footer">
                {disableButtonVisible && (
                  <button type="button" className="btn btn-primary" id="disablebutton" onClick={onclickdisable}>Enable</button>
                )}
                {/* <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button> */}
                <Link className="btn btn-secondary" to="/ApproverButton">Close</Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EnableCandidate;