import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import image from "../Photos/Gurudwaras-in-India.jpg";
import heading from "../Photos/heading.png";

function ApproverButton() {
  const history = useHistory();
  return (
    <>
    <div id="image123" style={{ backgroundImage: `url('${image}')`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", height: "650px", display: "block" }} >
    
      <div className="m-5">
        <div className="d-grid gap-2 d-md-block my-2">
          <Link className="btn btn-primary mx-1 fs-5" to="/ApplicantApproval" style={{ padding: "6px 44px" }} >Approve Applicants</Link>
          <Link className="btn btn-primary mx-1 fs-5" to="/ViewCandidate" style={{ padding: "6px 92px" }} >View All</Link>
        </div>
        <div className="d-grid gap-2 d-md-block my-2">
          <Link className="btn btn-primary mx-1 fs-5" to="/DisableCandidate" style={{ padding: "6px 47px" }} >Disable Candidates</Link>
          <Link className="btn btn-primary mx-1 fs-5" to="/EnableCandidate" style={{ padding: "6px 46px" }} >Enable Candidates</Link>
        </div>
        <div className="d-grid gap-2 d-md-block my-2">
          <Link className="btn btn-primary mx-1 fs-5" style={{ padding: "6px 63px" }} to="/ResetApproverPassword" >Reset Password</Link>
          <Link className="btn btn-primary mx-1 fs-5" style={{ padding: "6px 74px" }} to="/report" >View Report</Link>
        </div>
        <div className="d-grid gap-2 d-md-block my-2">
          <Link className="btn btn-danger mx-1 fs-5" style={{ padding: "6px 37px" }} to="/deleteAll" >Delete All Candidates</Link>
          <Link className="btn btn-danger mx-1 fs-5" style={{ padding: "6px 51px" }} to="/deleteCandidate">Delete Candidate</Link>
        </div>
        <div className="d-grid gap-2 d-md-block my-2">
          <Button className="btn btn-danger mx-1 fs-5" style={{ padding: "6px 100px" }} onClick={() => { localStorage.clear(); window.location.reload(); }}>Logout</Button>
        </div>
        <div className="d-grid gap-2 d-md-block my-2 mx-1">
        </div>
      </div>

      {/* <footer className="flex-container content text-primary fs-6" style={{ backgroundColor: '#f8f9fa', padding: '20px', textAlign: 'center' }}>
        Developed by: Harmanjot Singh (Ph: 8866116969)
      </footer> */}
      <footer className="fs-6 fixed-bottom" style={{color:"white", backgroundColor: '#99999b', padding: '13px', textAlign: 'center' }}>
        Developed by: Harmanjot Singh (Ph: 8866116969)
      </footer>
      </div>
    </>
  );
}

export default ApproverButton;
