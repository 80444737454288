import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import image from '../Photos/Gurudwaras-in-India.jpg';
import heading from "../Photos/heading.png";
import { useHistory } from "react-router-dom";

function ResendApplicantid() {
  const [applicantNumber, setApplicantNumber] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const history = useHistory();
  const gotodashboard = () => {
    history.push("/dashboard", { verified: true });
  }

  const getApplicationStatus = () => {
    Axios.post('https://api-gsm.mastersolutions.in/resendapplicantid', {
      applicantNumber: applicantNumber,
    })
      .then((response) => {
        if (response.data.includes('time')) {
          setModalMessage(`Candidate-ID sent to your verified Whatsapp number ${response.data}. Maximun allowed 3 times in a day.`);
          setShowModal(true);
        } else if (response.data == 'msg-limit') {
          setModalMessage('Candidate-ID already sent to your verified Whatsapp number 3 times today.');
          setShowModal(true);
        } else {
          setModalMessage('Please enter your registered Whatsapp number');
          setShowModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [projectHeading, setProjectHeading] = useState([]);

  useEffect(() => {
    Axios.post("https://api-gsm.mastersolutions.in/getprojectheading").then((response) => {
      setProjectHeading(response.data);
    });
  }, []);

  return (
    <>
      <div className="mx-3">
        <form>
          <h1 className="text-primary p-3 mt-2 col-md-5 text-center">
            {projectHeading.length > 0 ? projectHeading[0].projectheading :
              <>
                <div className="spinner-border fs-6" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            }
          </h1>
          <div className="col-md-5">
            <div className="">
              <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary border-3 mx-4 text-center" id="title">Request Applicant ID</div>
              <div className="my-3 col-md-7 mx-4">
                <label className="form-label fs-5 text-primary h1">Verified WhatsApp Number</label>
                <input type="number" className="form-control justify-content-center" id="exampleInputEmail1" onChange={(event) => { setApplicantNumber(event.target.value); }} autoFocus autoComplete="off" />
              </div>

              <div className="">
                <button type="button" style={{ marginLeft: '23px' }} className="btn btn-primary fs-5" onClick={() => { getApplicationStatus(); }} data-bs-toggle="modal" data-bs-target="#exampleModal" >Send</button>
                <Link onClick={gotodashboard} className="btn btn-primary mx-1 fs-5">Back</Link>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div>
        <img src={image} alt="Image not found" className="img-fluid d-md-none mt-2" />
      </div>

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-primary" id="exampleModalLabel">
                GSM says
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
            </div>
            <div className="modal-body text-primary"><b>{modalMessage}</b></div>
            <div className="modal-footer">
              <Link onClick={gotodashboard} data-bs-dismiss="modal" type="button" className="btn btn-secondary" autoFocus>Ok</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResendApplicantid;


