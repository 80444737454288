//Applicant
import ApplicantButton from "../components/Applicant/ApplicantButton";
import ApplicantRegistration from "../components/Applicant/ApplicantRegistration";
import PrintApplicationForm from "../components/Applicant/PrintApplicationForm";
import CheckApplicationStatus from "../components/Applicant/CheckApplicationStatus";

//Approver
import ApproverLogin from "../components/Approver/ApproverLogin";
import ApproverButton from "../components/Approver/ApproverButton";
import ViewCandidate from "../components/Approver/ViewCandidate";
import DisableCandidate from "../components/Approver/DisableCandidate";
import EnableCandidate from "../components/Approver/EnableCandidate";
import ResetApproverPassword from "../components/Approver/ResetApproverPassword";
import ApplicantApproval from "../components/Approver/ApplicantApproval";
import ResetCandidatePassword from "../components/Approver/ResetCandidatePassword";
import DeleteAlls from "../components/Approver/DeleteAlls";
import DeleteCandidate from "../components/Approver/DeleteCandidates";
import Reports from "../components/Approver/Reports";

// match
import FindAMatch from "../components/Match/FindAMatch";
import MatchButton from "../components/Match/MatchButton";
import ResetPassword from "../components/Match/ResetPassword";
import DisableMyProfile from "../components/Match/DisableMyProfile";
import EditProfile from "../components/Match/EditProfile";

//recommend
import IRecommend from "../components/Recommend/IRecommend";
import RecommenderLogin from "../components/Recommend/RecommenderLogin";

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MatchLogin from "../components/Match/MatchLogin";
import ResendApplicantid from "../components/Applicant/ResendApplicantid";
import EditApplicationForm from "../components/Applicant/EditApplicationForm";
import CandidateI from "../components/Approver/CandidateI";
import Example from "../components/Recommend/Example";
import LookigForGirl from "../components/Match/LookigForGirl";
import LookingForBoy from "../components/Match/LookingForBoy";
import IInterestedIn from "../components/Match/IInterestedIn";
import InterestedInMe from "../components/Match/InterestedInMe";
import NoBot from "../components/Captcha";
import UploadPhoto from "../components/Match/UploadPhoto";
import UploadFormPhoto from "../components/Applicant/uploadFormPhoto";

const PublicRoute = ({ children, role }) => {
  const authUser = localStorage.getItem("token");
  console.log("role", role);
  if (authUser) {
    const token = JSON.parse(atob(localStorage.getItem("token").split(".")[1]));
    if (token.role === "Candidate") {
      return <Redirect to="/match" />;
    } else if (token.role === "Approver") {
      return <Redirect to="/ApproverButton" />;
    }
  }

  return children;
};

const PrivateRoute = ({ children, role }) => {
  const authUser = localStorage.getItem("token");

  console.log("role", role);
  if (!authUser) {
    return <Redirect to="/login" />;
  }
  const token = JSON.parse(atob(localStorage.getItem("token").split(".")[1]));
  if (token.role === role) {
    return children;
  } else {
    return <Redirect to="/login" />;
  }
};

function App() {
  return (
    <>
      <Switch>
        {/* Applicant */}
        <Route exact path="/">
          <NoBot />
        </Route>
        <Route exact path="/dashboard" component={ApplicantButton} />

        {/* </Route> */}
        <Route exact path="/ApplicantRegistration">
          <ApplicantRegistration />
        </Route>
        <Route exact path="/CheckApplicationStatus">
          <CheckApplicationStatus />
        </Route>
        <Route exact path="/printApplicationform">
          <PrintApplicationForm />
        </Route>
        <Route exact path="/resendapplicantid">
          <ResendApplicantid />
        </Route>
        <Route exact path="/EditApplicationForm">
          <EditApplicationForm />
        </Route>
        <Route exact path="/uploadformphoto">
          <UploadFormPhoto />
        </Route>

        {/*Login Common Candidate & Approver*/}

        <Route
          exact
          path="/login"
          component={() => (
            <PublicRoute>
              <MatchLogin />
            </PublicRoute>
          )}
        />

        {/* Match */}

        <Route
          exact
          path="/match"
          component={() => (
            <PrivateRoute role="Candidate">
              <MatchButton />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/ResetPassword"
          component={() => (
            <PrivateRoute role="Candidate">
              <ResetPassword />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/FindAMatch"
          component={() => (
            <PrivateRoute role="Candidate">
              <FindAMatch />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/uploadphoto"
          component={() => (
            <PrivateRoute role="Candidate">
              <UploadPhoto />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/disableMyProfile"
          component={() => (
            <PrivateRoute role="Candidate">
              <DisableMyProfile />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/editprofile"
          component={() => (
            <PrivateRoute role="Candidate">
              <EditProfile />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/Lookingforgirl"
          component={() => (
            <PrivateRoute role="Candidate">
              <LookigForGirl />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/Lookingforboy"
          component={() => (
            <PrivateRoute role="Candidate">
              <LookingForBoy />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/InterestedInMe"
          component={() => (
            <PrivateRoute role="Candidate">
              <InterestedInMe />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/IInterestedIn"
          component={() => (
            <PrivateRoute role="Candidate">
              <IInterestedIn />
            </PrivateRoute>
          )}
        />

        {/* approver */}
        {/* <Route
          exact
          path="/approverlogin"
          component={() => (
            <PublicRoute role="Approver">
              <ApproverLogin />
            </PublicRoute>
          )}
        /> */}
        <Route
          exact
          path="/ApproverButton"
          component={() => (
            <PrivateRoute role="Approver">
              {" "}
              <ApproverButton />{" "}
            </PrivateRoute>
          )}
        />

        <Route
          exact
          path="/deleteAll"
          component={() => (
            <PrivateRoute role="Approver">
              <DeleteAlls />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/deleteCandidate"
          component={() => (
            <PrivateRoute role="Approver">
              {" "}
              <DeleteCandidate />{" "}
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/report"
          component={() => (
            <PrivateRoute role="Approver">
              {" "}
              <Reports />{" "}
            </PrivateRoute>
          )}
        />

        <Route
          exact
          path="/ApplicantApproval"
          component={() => (
            <PrivateRoute role="Approver">
              <ApplicantApproval />
            </PrivateRoute>
          )}
        />

        <Route
          exact
          path="/ViewCandidate"
          component={() => (
            <PrivateRoute role="Approver">
              <ViewCandidate />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/DisableCandidate"
          component={() => (
            <PrivateRoute role="Approver">
              <DisableCandidate />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/EnableCandidate"
          component={() => (
            <PrivateRoute role="Approver">
              <EnableCandidate />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/ResetCandidatePassword"
          component={() => (
            <PrivateRoute role="Approver">
              <ResetCandidatePassword />
            </PrivateRoute>
          )}
        />
        <Route
          exact
          path="/ResetApproverPassword"
          component={() => (
            <PrivateRoute role="Approver">
              <ResetApproverPassword />
            </PrivateRoute>
          )}
        />

        {/* Recommend */}
        <Route exact path="/recommenderlogin">
          <RecommenderLogin />
        </Route>
        <Route exact path="/IRecommend">
          <IRecommend />
        </Route>

        <Route exact path="/candidatei">
          <CandidateI />
        </Route>
        <Route exact path="/example">
          <Example />
        </Route>
      </Switch>
    </>
  );
}

export default App;
