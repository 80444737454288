import React, { useState, useEffect } from 'react';

import axios from 'axios';

function Example() {
  const [inputValue1, setInputValue1] = useState('');
  const [inputValue2, setInputValue2] = useState('');
  const [inputValue3, setInputValue3] = useState('');
  const [inputValue4, setInputValue4] = useState('');
  const [inputValue5, setInputValue5] = useState('');

  const handleBlur = async (inputName, value) => {
    try {
      console.log([inputName], value);
      await axios.post('https://api-gsm.mastersolutions.in/updatecandidate', {
        [inputName]: value,
      });
      console.log(`${inputName} saved to the server`);
    } catch (error) {
      console.error(`Error saving ${inputName} to the server`, error);
    }
  };

  const [ddls, setDdls] = useState([]);
  const [dbMaritalStatusOption, setDbMaritalStatusOption] = useState([]);



  useEffect(() => {
    axios.post("https://api-gsm.mastersolutions.in/ddls").then((response) => {
      setDdls(response.data);
      setDbMaritalStatusOption(response.data[0].marital_status.split(','))
    });
    axios.post("https://api-gsm.mastersolutions.in/candidatefillapplicationdata").then((response) => {
      setInputValue1(response.data.map((val, key) => val.candidate_firstname));
      setInputValue2(response.data.map((val, key) => val.candidate_middlename));
      setInputValue3(response.data.map((val, key) => val.candidate_lastname));
      setInputValue4(response.data.map((val, key) => val.candidate_current_address));
      setInputValue5(response.data.map((val, key) => val.marital_status));
    });
  }, []);


  return (
    <div>
      <div>
        <span>first name</span>
        <input
          type="text"
          placeholder="Enter input 1 here"
          value={inputValue1}
          onChange={(e) => setInputValue1(e.target.value)}
          onBlur={() => handleBlur('candidate_firstname', inputValue1)}
        />
      </div>
      <div>
        <span>middle name</span>
        <input
          type="text"
          placeholder="Enter input 2 here"
          value={inputValue2}
          onChange={(e) => setInputValue2(e.target.value)}
          onBlur={() => handleBlur('candidate_middlename', inputValue2)}
        />
      </div>
      <div>
        <span>last name</span>
        <input
          type="text"
          placeholder="Enter input 3 here"
          value={inputValue3}
          onChange={(e) => setInputValue3(e.target.value)}
          onBlur={() => handleBlur('candidate_lastname', inputValue3)}
        />
      </div>
      <div>
        <span>Address</span>
        <input
          type="text"
          placeholder="Enter input 4 here"
          value={inputValue4}
          onChange={(e) => setInputValue4(e.target.value)}
          onBlur={() => handleBlur('candidate_current_address', inputValue4)}
        />
      </div>
      <div>
        <span>Marital Status</span>
        <select onChange={(e) => setInputValue5(e.target.value)} onBlur={() => handleBlur('marital_status', inputValue5)}>
          <option disabled selected={inputValue5==''?true:false}>
            Select Marital Status
          </option>
          {dbMaritalStatusOption.map((val, key) => {
            return (
              <>
                <option value={val} selected={inputValue5==val?true:false}>{val}</option>
              </>
            );
          })
          }
        </select>
      </div>
      <p id="output">
        You entered: | {inputValue1} | {inputValue2} | {inputValue3} | {inputValue4} | {inputValue5} |
      </p>
    </div>
  );
};

export default Example
