import axios from "axios";
import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

function NoBot() {
  const history = useHistory();
  const captchaRef = useRef(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [loading, setLoading] = useState(true); // Added loading state

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulating 2 seconds of loading time

    return () => clearTimeout(timeout); // Clean up timeout on component unmount
  }, []);

  const handleCaptchaChange = async (token) => {
    console.log("token123", token);
    if (token) {
      setCaptchaVerified(true);
      try {
        const tokenreCAPTCHA = captchaRef.current.getValue();
        captchaRef.current.reset();
        console.log("re", tokenreCAPTCHA);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/entry`,
          {
            token: tokenreCAPTCHA,
          }
        );

        if (response.status === 200) {
          history.replace("/dashboard", { verified: true });
        } else {
          alert("Wrong reCAPTCHA");
        }
      } catch (error) {
        // Handle login error
        alert(error.response.data.message);
        console.error(error);
      }
    } else {
      setCaptchaVerified(false);
    }
  };

  return (
    <div
      id="loginmargin"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      Click on I'm Not Robot
      <br />
      {loading ? ( // Conditional rendering based on loading state
        <>
          <div className="spinner-border text-primary mt-3" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </>
      ) : (
        <>
          <form className="mt-2">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY_NEW}
              ref={captchaRef}
              onChange={handleCaptchaChange}
            />
          </form>
        </>
      )}
    </div>
  );
}

export default NoBot;
