import { Link } from "react-router-dom";
import image from "../Photos/Screenshot2023-01-05112943.png";
import heading from "../Photos/heading.png";
import bride from "../Photos/Bridee.png";
import groom from "../Photos/Groomm.png";
import "./match.css";
import { Button } from "react-bootstrap";

function MatchButton() {
  // const history = useHistory();
  const candidateName = localStorage.getItem("candidate_name")
  const candidate_id = localStorage.getItem("candidate_id");

  return (
    <>
    <div id="image123" style={{ backgroundImage: `url('${image}')`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", height: "100vh", display: "block" }} >

      <div className="col-xxl-5 col-xl-7 col-lg-8 col-md-9 mx-5">
        <div className="row">
          <img src={bride} alt="..." style={styles.img} className="img-fluid d-sm-none d-none d-md-block" />
          <img src={groom} alt="..." style={styles.img} className="img-fluid d-sm-none d-none d-md-block" />
        </div>
        <div className="row">
          <Link className="btn btn-primary fs-3 mx-2 my-1" to="/Lookingforgirl" style={{ flex: "1" }} id="button" >Look For Bride</Link>
          <Link className="btn btn-primary fs-3 mx-2 my-1" to="/Lookingforboy" style={{ flex: "1" }} id="button" >Look For Groom</Link>
        </div>
        <div className="row">
          <Link className="btn btn-primary fs-3 mx-2 my-1" to="/InterestedInMe" style={{ flex: "1" }} id="button" >Interested In Me</Link>
          <Link className="btn btn-primary fs-3 mx-2 my-1" to="/IInterestedIn" style={{ flex: "1" }} id="button" >I'm Interested In</Link>
        </div>
        <div className="row">
          <Link className="btn btn-primary fs-3 mx-2 my-1" to="/EditProfile" style={{ flex: "1" }} id="button" >Edit My Profile</Link>
          <Link className="btn btn-primary fs-3 mx-2 my-1" to="/uploadphoto" style={{ flex: "1" }} id="button" >Upload photo</Link>
        </div>
        <div className="row">
          <Link className="btn btn-primary fs-3 mx-2 my-1" to="/ResetPassword" style={{ flex: "1" }} id="button" >Reset Password</Link>
          <Link className="btn btn-danger fs-3 mx-2 my-1" to="/Disablemyprofile" style={{ flex: "1" }} id="button" >Disable My Profile</Link>
        </div>
        <div className="row">
          <Button className="btn fs-3 mx-2 my-1" variant="danger" style={{ flex: "1" }} onClick={() => { localStorage.clear(); window.location.reload(); }}>Logout</Button>
        </div>
        <span className="text-primary fs-4">Logged in by: {candidateName} ({candidate_id})</span>
      </div>

      <footer className="fs-6 fixed-bottom" style={{color:"white", backgroundColor: '#99999b', padding: '13px', textAlign: 'center' }}>
        Developed by: Harmanjot Singh (Ph: 8866116969)
      </footer>
      {/* <footer className="flex-container content text-primary fs-6 mt-3  " style={{ backgroundColor: '#f8f9fa', padding: '20px', textAlign: 'center' }}>
        Developed by: Harmanjot Singh (Ph: 8866116969)
      </footer> */}
      </div>
    </>
  );
}


const styles = {
  img: {
    width: "200px",
    height: "250px",
    flex: "1",
    marginLeft: "50px",
    marginRight: "50px",
  },
};

export default MatchButton;
