import { Link } from "react-router-dom";


function ResetCandidatePassword() {

    return (
        <>
            <div className="d-grid gap-2 col-md-3 my-4 mx-3">
                <input type="number" className="form-control" placeholder="Candidate ID" />
                <input type="number" className="form-control" placeholder="Password" />
                <input type="number" className="form-control" placeholder="Confirm Password" />
                <div className="text-center">
                    <Link className="btn btn-primary mx-1 fs-5 col-5" to="/ApproverButton">Save</Link>
                    <Link className="btn btn-primary mx-1 fs-5 col-5" to="/ApproverButton">Back</Link>
                </div>
            </div>
            {/* <input type="text" className="mx-3 my-2" placeholder="Candidate ID"/><br />
            <input type="text" className="mx-3 my-2" placeholder="Password"/><br />
            <input type="text" className="mx-3 my-2" placeholder="Confirm Password"/><br />
            <a href="" className='mx-4'>Save</a>
            <Link to="/" className='mx-4'>Back</Link> */}
        </>
    );
}

export default ResetCandidatePassword;