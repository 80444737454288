import React from 'react'

function RecommenderLogin() {
    return (
        <div id='loginmargin'>
            <div className='mx-3'>
                <form>
                    <div className="col-sm-5 my-3">
                        <label className="form-label">User Name</label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="col-sm-5 my-3">
                        <label className="form-label">Password</label>
                        <input type="password" className="form-control" />
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default RecommenderLogin
