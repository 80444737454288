import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import apiClient from "../../api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import heading from "../Photos/heading.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';


function UploadFormPhoto() {
  const location = useLocation();
  const history = useHistory();
  const gotodashboard = () => {
    history.push("/dashboard", { verified: true });
  }
  const {
    CandidateID,
    phoneNo,
    CandidateFullName,
    candidatelastName,
    candidateSurname,
    referenceName,
    referenceNumber,
    Gender,
    isEdit,
    countofsendingreference,
  } = location.state;

  const [uploadData, setUploadData] = useState({
    profilephoto: "",
    fullphoto: "",
  });
  const [getUploadCount, setGetUploadCount] = useState({
    profilephoto: 0,
    fullphoto: 0,
  });

  const profileRef = useRef();
  const fullRef = useRef();

  const formhandler = async (e) => {
    let err = null;
    e.preventDefault();
    const formData = new FormData();

    uploadData.profilephoto &&
      formData.append(
        "profilephoto",
        uploadData.profilephoto,
        uploadData.profilephoto.name
      );
    uploadData.fullphoto &&
      formData.append(
        "fullphoto",
        uploadData.fullphoto,
        uploadData.fullphoto.name
      );

    if (!isEdit) {
      apiClient
        .post("https://api-gsm.mastersolutions.in/sendingreference", {
          CandidateNumber: phoneNo,
          CandidateID: CandidateID,
          CandidateFullName: CandidateFullName,
          candidatelastName: candidatelastName,
          candidateSurname: candidateSurname,
          referenceName: referenceName,
          referenceNumber: referenceNumber,
          Gender: Gender,
        }).then(() => {
          setShow(true);
          setModalMessage("Your application has been saved. It will be visible to other candidates after the approval of the reference person.");
        }).catch((error) => {
          if (error?.response.status === 400) {
            setShow(true);
            setModalMessage(
              error.response.data.message
            );
          }
          err = error;
        });
    } else {
      apiClient
        .post("https://api-gsm.mastersolutions.in/sendingreferencefromedit", {
          CandidateNumber: phoneNo,
          CandidateID: CandidateID,
          CandidateFullName: CandidateFullName,
          candidatelastName: candidatelastName,
          candidateSurname: candidateSurname,
          referenceName: referenceName,
          referenceNumber: referenceNumber,
          Gender: Gender,
          countofsendingreference: countofsendingreference,
        }).then(() => {
          setShow(true);
          setModalMessage("Your application has been saved. It will be visible to other candidates after the approval of the reference person.");
        }).catch((error) => {
          if (error?.response.status === 400) {
            setShow(true);
            setModalMessage(
              error.response.data.message
            );
          }
          err = error;
        });
    }
    try {
      await apiClient
        .post(`/uploadDataForm?candidateId=${CandidateID}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          apiClient
            .get(`/getUploadCount2?candidateId=${CandidateID}`)
            .then((res) => {
              setGetUploadCount(res.data.data);
            });
        });
    } catch (error) {
      if (error?.response.status === 400) {
        setShow(true);
        setModalMessage(
          error.response.data.message
        );
      }
      err = error;
    }
  };

  const profileHandler = (event) => {
    console.log("event", event.target.files);
    setUploadData((prev) => ({ ...prev, profilephoto: event.target.files[0] }));
  };
  const fullPhotoHandler = (event) => {
    console.log("event", event.target.files);
    setUploadData((prev) => ({ ...prev, fullphoto: event.target.files[0] }));
  };

  const removeSelectedProfile = () => {
    profileRef.current.value = "";
    setUploadData((prev) => ({ ...prev, profilephoto: "" }));
  };
  const removeSelectedFullPhoto = () => {
    fullRef.current.value = "";
    setUploadData((prev) => ({ ...prev, fullphoto: "" }));
  };


  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    axios.post('https://api-gsm.mastersolutions.in/getphotosfromimgtable', {
      CandidateID: CandidateID,
    }).then((response) => {
      setPhotos(response.data);

      if (response.data && response.data[0]) {
        const profilephotoNumber = parseInt(response.data[0].profilephoto.match(/\d+(?=\.)/)?.[0] || '0', 10);
        const fullphotoNumber = parseInt(response.data[0].fullphoto.match(/\d+(?=\.)/)?.[0] || '0', 10);

        setGetUploadCount(prevState => ({
          ...prevState,
          profilephoto: profilephotoNumber,
          fullphoto: fullphotoNumber,
        }));
      }
    });
    return () => {
      if (uploadData.profilephoto) {
        URL.revokeObjectURL(uploadData.profilephoto);
      }
      if (uploadData.fullphoto) {
        URL.revokeObjectURL(uploadData.fullphoto);
      }
    };
  }, []);

  const [modalMessage, setModalMessage] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  let a = ''
  let b = ''
  if (photos.length > 0) {
    a = photos[0].profilephoto;
    b = photos[0].fullphoto;
  } else {
    console.log('Photos array is empty.');
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Header>
          <Modal.Title className="text-primary">GSM says</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-primary">{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose} autoFocus>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <form action="" onSubmit={formhandler} encType="multipart/form-data">
        <div className="p-3 text-primary col-md-12 text-center" >
          <img src={heading} alt="" className="col-md-4 text-center img-fluid" />
        </div>
        <h2 className="text-primary text-center col-md-12">Upload Photos</h2>
        <p className="text-danger text-center text-bold mx-2 fs-3">Photos upload is compulsory. <br /> Your profile won't be published without photos.</p>
        <div className="row justify-content-center mx-2">
          <div className="col-md-5 mx-2">
            <label className="text-primary fs-5">Profile Photo</label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={profileHandler}
              style={{ display: "inline-block" }}
              ref={profileRef}
              required
            />{" "}
            <div className="d-flex gap-4 mt-4 justify-content-center">
              {a.length > 0 ?
                <img src={`https://api-gsm.mastersolutions.in/uploads1/${a}`} width="200px" height="150px" alt="" className="img-fluid" />
                :
                <></>
              }
              {uploadData.profilephoto && (
                <div>
                  <div className="text-center">
                    <img
                      className="my-3"
                      src={URL.createObjectURL(uploadData.profilephoto)}
                      alt="Thumb"
                      width="200px"
                      height="200px"
                    />
                  </div>

                  <button
                    type="button"
                    className="form-control btn btn-primary"
                    onClick={() => removeSelectedProfile()}
                  >
                    Remove this Image
                  </button>
                </div>
              )}
            </div>
            <div>
            </div>
          </div>
          <div className="d-flex col-auto">
            <span className=" fs-2 border border-2 border-primary d-none d-md-inline"></span>
          </div>
          <div className="col-sm-5 mx-2">
            <label className="text-primary fs-5">Full Photo</label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={fullPhotoHandler}
              ref={fullRef}
              required
            />
            <div className="d-flex gap-4 mt-4 justify-content-center">
              {a.length > 0 ?
                <img src={`https://api-gsm.mastersolutions.in/uploads1/${b}`} width="200px" height="300px" alt="" className="img-fluid" />
                :
                <></>
              }
              {uploadData.fullphoto && (
                <div>
                  <div className="text-center">
                    <img
                      className="my-3"
                      src={URL.createObjectURL(uploadData.fullphoto)}
                      alt="Thumb"
                      width="200px"
                      height="200px"
                    />
                  </div>

                  <button
                    type="button"
                    className="form-control btn btn-primary"
                    onClick={() => removeSelectedFullPhoto()}
                  >
                    Remove this Image
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12 justify-content-center text-center mt-4 mb-5">
          <button type="submit" className="btn btn-primary mx-1 fs-5 col-md-2">
            Upload & Save
          </button>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 text-primary" id="exampleModalLabel">GSM Says</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <b className="text-primary">Your application has been saved. It will be visible to other candidates after the approval of the reference person.</b>
              </div>
              <div className="modal-footer">
                <Link onClick={gotodashboard} className="btn btn-secondary" data-bs-dismiss="modal">Ok</Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default UploadFormPhoto;
