import React from 'react';
import apiClient from '../../api';
import { useState, useEffect, useRef } from 'react';

function Reports() {
  useEffect(() => {
    getUsers();
  }, []);

  const [users, setUser] = useState([]);

  const getUsers = async () => {
    const response = await apiClient.post('/getreport');
    setUser(response.data);
    console.log(response.data);
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength - 3) + '...';
    }
    return str;
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th style={{ border: '1px solid black',textAlign:"center" }}>candidate-id<br />status</th>
            <th style={{ border: '1px solid black',textAlign:"center"  }}>F-name <br /> M-name <br /> L-name</th>
            <th style={{ border: '1px solid black', maxWidth: '200px' ,textAlign:"center" ,whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Current Address</th>
            <th style={{ border: '1px solid black',textAlign:"center"  }}>DOB <br /> Marital s. <br /> Religion</th>
            <th style={{ border: '1px solid black',textAlign:"center"  }}>Gender<br />Height<br />Weight</th>
            <th style={{ border: '1px solid black',textAlign:"center"  }}>WA Num<br />Email Id</th>
            <th style={{ border: '1px solid black',textAlign:"center"  }}>Qualifi.<br />Degree<br />C. income</th>
            <th style={{ border: '1px solid black',textAlign:"center"  }}>F. income<br />p. address</th>
            <th style={{ border: '1px solid black',textAlign:"center"  }}>RF Name<br />RF Num</th>
            <th style={{ border: '1px solid black',textAlign:"center"  }}>Approved By<br />Date of Approval</th>
          </tr>
        </thead>
        <tbody>
          {users.map((val, key) => {
            return (
              <tr key={key}>
                <td style={{ border: '1px solid black', width: '120px',textAlign:"center"}}>{val.Candidate_id} <br />{val.Status}</td>
                <td style={{ border: '1px solid black', width: '140px',textAlign:"center" }}>{val.candidate_firstname}<br />{val.candidate_middlename}<br />{val.candidate_lastname}</td>
                <td style={{ border: '1px solid black', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{truncateString(val.Candidate_Current_Address, 30)}</td>
                <td style={{ border: '1px solid black', width: '135px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' ,textAlign:"center"  }}>{new Date(val.Date_of_Birth).toLocaleDateString('en-GB')}<br />{val.Marital_Status}<br />{val.Religion}</td>
                <td style={{ border: '1px solid black', width: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',textAlign:"center"   }}>{val.Gender}<br />{val.height_cm}<br />{val.Weight}</td>
                <td style={{ border: '1px solid black', width: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' ,textAlign:"center"  }}>{val.WhatsApp_Number}<br />{truncateString(val.Email_ID,20)}</td>
                <td style={{ border: '1px solid black', width: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'  ,textAlign:"center" }}>{val.Qualification}<br />{val.Degree}<br />{val.Candidate_Annual_Income}</td>
                <td style={{ border: '1px solid black', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'  }}>{val.Family_Annual_Income}<br />{val.family_permanent_address}</td>
                <td style={{ border: '1px solid black', width: '130px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' ,textAlign:"center"  }}>{ truncateString(val.Reference_Name,20)}<br />{val.Reference_Contact}</td>
                <td style={{ border: '1px solid black', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',textAlign:"center"  }}>{val.Approved_By}<br/>{val.Date_of_approval}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Reports;
