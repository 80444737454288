import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

apiClient.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token") || "";
    request.headers = {
      Authorization: `Bearer ${token}`,
    };
    return request;
  },
  (error) => {
    throw error;
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();

      window.location =
        window.location.protocol + "//" + window.location.host + "/login";

      throw error;
    }

    throw error;
  }
);

export default apiClient;
