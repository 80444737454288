import React from "react";
import AllRoutes from "./routes/appRoutes";

import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <AllRoutes />
    </Router>
  );
}

export default App;
