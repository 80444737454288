import React from 'react'
import { Link } from "react-router-dom";
import pdf from '../Applicant/ApplicationFormPrintable221213.pdf'
import heading from "../Photos/heading.png";
import { useHistory } from "react-router-dom";


function PrintApplicationForm() {

  const history = useHistory();
  const gotodashboard = () => {
    history.push("/dashboard", { verified: true });
  }

  return (
    <>
      <div className="mt-2 text-primary text-center sticky-top" style={{"backgroundColor":"white"}}>
        <img src={heading} alt="" class="col-md-5 text-center img-fluid" />
      </div>
      <div className='container'>
        <Link onClick={gotodashboard} className='btn btn-primary mt-2'>Back</Link>
        <iframe className='border border-5 my-3' src={pdf} style={{ width: "100%", height: "550px" }} title="myFrame"></iframe>
      </div>
    </>
  )
}

export default PrintApplicationForm;