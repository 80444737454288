import React from 'react'
import apiClient from "../../api";

function DeleteAll() {

  const onclickdeleteall = () => {
    apiClient.post("/deleteall");
  }

  return (
    <>
      <button type="button" class="btn btn-primary m-2" data-bs-toggle="modal" data-bs-target="#exampleModal">Delete All Candidates</button>

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">GSM says</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Are you sure
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" onClick={onclickdeleteall}  data-bs-dismiss="modal">Yes</button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteAll;